<template>
  <div class="container">
    <div class="index_section updateAdmin_cs">
        <van-nav-bar
          :title="titleVal"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="iptF_cs2">
            <van-form >
              <div>
                <div class="field_cs">
                    <van-field v-model="query.name" placeholder="请输入联系人姓名" maxlength="10" label="联系人姓名">
                      <template #label>
                          联系人姓名: <span style="color: red; font-size: 16px">*</span>
                      </template>
                    </van-field>
                </div>
                <div class="field_cs">
                    <van-field v-model="query.phone" placeholder="请输入联系人手机号" maxlength="11" label="联系人电话">
                      <template #label>
                          联系人电话: <span style="color: red; font-size: 16px">*</span>
                      </template>
                    </van-field>
                </div>
                <div class="field_cs">
                    <van-field v-model="query.dutuies" placeholder="请输入联系人职务" maxlength="5" label="职务">
                      <template #label>
                          职务: <span style="color: red; font-size: 16px">*</span>
                      </template>
                    </van-field>
                </div>
                <div class="field_cs">
          <van-cell
              title="授权范围:"
              class="van-ellipsis"
              @click="show = true"
            >
            <template #title>
                <span style="font-size: 14px; color: #646566;">授权范围:</span> <span style="color: red; font-size: 15px;">*</span>
            </template>
            <div class="sk2">
              <span v-if="query.impower" style="margin-right: 15px; color: black;">{{query.impower}}</span>
              <span v-else style="margin-right: 15px">请选择授权范围</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet v-model="show" :actions="$store.state.ranges"   cancel-text="取消" close-on-click-action @cancel="onCancel" :close-on-click-overlay="false" @select="onSelect" />
                </div>
                <div class="field_cs" v-if="$route.query.id" style="font-size: 14px; color: #646566; padding: 10px 16px;">
                    是否重置登录密码:<span style="color: red; font-size: 16px; margin-right: 15px;">*</span><van-switch v-model="query.isResetPass" size='18'/>
                </div>
                <div class="field_cs" v-if=" !($route.query.id) || query.isResetPass">
                    <van-field v-model="query.passWrod" :type="passwordOff?'password':'text'" placeholder="请输入登录密码" maxlength="18" label="登录密码">
                        <template slot="right-icon">
                            <img :src="passwordOff?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="18px" height="18px" alt="" @click="passwordOff=!passwordOff">
                        </template>
                    </van-field>
                </div>
              </div>

            <div style="margin: 80px 16px 10px;">
              <van-button round block type="info" @click="onSubmit">提交</van-button>
            </div>
          </van-form>
        </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { Toast } from 'vant';
let telReg = /^1[3,4,5,6,7,8,9]{1}\d{9}$/;
let posswordReg = /^(\w){6,18}$/; //只能输入6-18位字母、数字、下划线
export default {
  data() {
    return {
      passwordOff: true,
      show: false,
        titleVal: '',
        query:{
          name: '',
          phone: '',
          dutuies: '',
          impower: '',
          isResetPass: false,
          passWrod: '',
        }
    }
  },
  created(){
      if(this.$route.query.id){
          Toast.loading({
            message: '加载中...',
            overlay: true,
            duration: 300,
            forbidClick: true,
          });
          setTimeout(()=>{
            this.getLinkmanList();
          }, 100)
          this.titleVal = '编辑联系人';
      }else{
          this.titleVal = '新建联系人';
      }
  },
  methods: {
      // 取消
      onCancel(){},
      // 监听选择的值
      onSelect(action){
        this.query.impower = action.name;
      },
      //提交 
      onSubmit(){
        if(!this.query.name || this.query.name.trim().length == 0){
          Notify({ type: 'danger', message: '请输入联系人姓名!!!'})
          return false;
        }
        if(!this.query.phone || this.query.phone.trim().length == 0){
          Notify({ type: 'danger', message: '请输入联系人手机号!!!'})
          return false;
        }
        if(!telReg.test(this.query.phone)){
          Notify({ type: 'danger', message: '联系人手机号格式错误!!!'})
          return false;
        }
        if(!this.query.dutuies || this.query.dutuies.trim().length == 0){
          Notify({ type: 'danger', message: '请输入联系人职务!!!'})
          return false;
        }
        if(!this.query.impower || this.query.impower.trim().length == 0){
          Notify({ type: 'danger', message: '请选择授权范围!!!'})
          return false;
        }
        if(!(this.$route.query.id)){
            if(!this.query.passWrod || this.query.passWrod.trim().length == 0){
              Notify({ type: 'danger', message: '请输入登录密码!!!'})
              return false;
            }
            if(!posswordReg.test(this.query.passWrod)){
              Notify({ type: 'danger', message: '登录密码只能输入6-18位字母、数字、下划线!!!'})
              return false;
            }
        }

        Toast.loading({
          message: '提交中...',
          overlay: true,
          duration: 300,
          forbidClick: true,
        });
        let impowerRow = this.query.impower == '订单发货' ? '1' : '2';
        let row = {
          allowLogin: 1,//是否允许登录
          jobTitle: this.query.dutuies,
          phone: this.query.phone,
          merchantId: localStorage.getItem('merchantId'),
          name: this.query.name,
          branchId: null,//门店id
          resources: impowerRow,
          password: this.$md5(this.query.passWrod),
          id: '',
        }
        if( this.$route.query.id ){
            row.id = this.$route.query.id;
            if(this.query.isResetPass){
              if(!this.query.passWrod || this.query.passWrod.trim().length == 0){
                Notify({ type: 'danger', message: '请输入登录密码!!!'})
                return false;
              }
              if(!posswordReg.test(this.query.passWrod)){
                Notify({ type: 'danger', message: '登录密码只能输入6-18位字母、数字、下划线!!!'})
                return false;
              }
            }else{
                row.password = undefined;
                this.query.passWrod = '';
            }
            this.$http.ajax('/ffkj-main/merchantOtherContact/merchantOtherContactEdit', row ,'put',{}).then(res=>{
                if( res.code == 0 ){
                  Notify({ type: 'success', message: '编辑成功!' })
                  this.$router.push({
                    path: '/linkmanPerson',
                    query: {}
                  })
                }
                else{
                  Notify({ type: 'danger', message: res.message })
                }
            })
        }else{
            this.$http.ajax('/ffkj-main/merchantOtherContact/merchantOtherContactAdd', row ,'post',{}).then(res=>{
                if( res.code == 0 ){
                  Notify({ type: 'success', message: '新建成功!' })
                  this.$router.push({
                    path: '/linkmanPerson',
                    query: {}
                  })
                }
                else{
                  Notify({ type: 'danger', message: res.message })
                }
            })
        }
      },
      // 返回
      onClickLeft() {
          window.history.go(-1);
      },
      // 联系人列表数据回显
      getLinkmanList(){
        this.$http
        .ajax('/ffkj-main/merchantOtherContact/getMerchantOtherContact',{
            id: this.$route.query.id,
        },'get',{}).then(res=>{
            if( res.code == 0){
               this.query.name = res.data.name;
               this.query.phone = res.data.phone;
               this.query.dutuies = res.data.jobTitle;
               this.query.impower = (res.data.resources == 1 ? '订单发货' : res.data.resources == 2 ? '合同结算' : res.data.resources)
            }
            else{
                Notify({ type: 'danger', message: res.message })
            }
            var timer = setTimeout(function(){
                window.clearTimeout(timer)
            }.bind(this), 300)
        })
      }
  }
}
</script>
<style >
  .el-textarea__inner,.el-input__inner { 
    background: transparent !important;
  }
</style>
<style scoped>
</style>